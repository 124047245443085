import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { getProofRequirement } from "common/transactions/new";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES } from "constants/notifications";
import { captureException } from "util/exception";
import {
  businessTransactionEditRoute,
  businessEsignTransactionEditRoute,
  businessProofTransactionEditRoute,
  businessIdentifyTransactionEditRoute,
} from "util/routes";
import {
  PROOF_TRANSACTION_TYPE,
  ESIGN_TRANSACTION_TYPE,
  SIGN_NOW_TRANSACTION_TYPE,
  IDENTIFY_TRANSACTION_TYPE,
} from "constants/transaction";
import { useActiveOrganization } from "common/account/active_organization";
import LoadingIndicator from "common/core/loading_indicator";
import { useQuery, useMutation } from "util/graphql";

import DefaultAuthenticationRequirement, {
  type DefaultAuthenticationRequirement_organization_Organization as Organization,
} from "./index.query.graphql";
import CreateOrganizationTransactionV2 from "./create_org_transaction_v2.mutation.graphql";

function NewTransaction() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data } = useQuery(DefaultAuthenticationRequirement, {
    variables: { organizationId: activeOrganizationId! },
  });
  const createTransaction = useMutation(CreateOrganizationTransactionV2);
  const [searchParams] = useSearchParams();
  const isEsignTransaction = searchParams.get("type") === ESIGN_TRANSACTION_TYPE;
  const isProofTransaction = searchParams.get("type") === PROOF_TRANSACTION_TYPE;
  const isIdentifyTransaction = searchParams.get("type") === IDENTIFY_TRANSACTION_TYPE;

  const navigate = useNavigate();

  const redirectToNewTransaction = async (
    editRoute: ({ id }: { id: string }) => string,
    organization: Organization,
  ) => {
    try {
      const response = await createTransaction({
        variables: {
          input: {
            organizationId: activeOrganizationId!,
            transaction:
              searchParams.get("type") === SIGN_NOW_TRANSACTION_TYPE
                ? { signOnDeviceRequired: true }
                : {},
            customers: [
              isProofTransaction
                ? {
                    proofRequirement: getProofRequirement(organization),
                  }
                : {},
            ],
          },
        },
      });
      const transaction = response.data!.createOrganizationTransactionV2!.organizationTransaction;
      navigate(editRoute(transaction), { replace: true });
    } catch (error) {
      captureException(error);
      pushNotification({
        message: (
          <FormattedMessage
            id="a496863b-8972-4a88-ae03-1d6bb67fa641"
            defaultMessage="Failed to create transaction"
          />
        ),
        subtype: NOTIFICATION_SUBTYPES.ERROR,
      });
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const organization = data.organization as Organization;
    if (isEsignTransaction) {
      redirectToNewTransaction(businessEsignTransactionEditRoute, organization);
    } else if (isProofTransaction) {
      redirectToNewTransaction(businessProofTransactionEditRoute, organization);
    } else if (isIdentifyTransaction) {
      redirectToNewTransaction(businessIdentifyTransactionEditRoute, organization);
    } else {
      redirectToNewTransaction(businessTransactionEditRoute, organization);
    }
  }, [data]);

  return <LoadingIndicator />;
}

export default NewTransaction;
