import type { ComponentProps } from "react";

import TtlExpiredAlert, { isTtlExpired } from "common/details/summary/ttl_expired_alert";
import { usePermissions } from "common/core/current_user_role";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";

import DocumentsListContainer from "./list.container";
import type { DocumentBundleForTransactionDetailsList } from "./list_fragment.graphql";

type ListContainerProps = ComponentProps<typeof DocumentsListContainer>;
type Props = {
  bundle: DocumentBundleForTransactionDetailsList;
  lastOpenedId: string | null;
  onDocumentSelected: ListContainerProps["onDocumentSelected"];
  onSupplementaryDocSelected: ListContainerProps["onSupplementaryDocSelected"];
  viewable?: boolean;
  downloadable?: boolean;
  shareable?: boolean;
  showHeader?: boolean;
  showLegalInfoItem?: boolean;
  showMergedDocs?: boolean;
  showNotarialActs?: boolean;
  showResponsiveView?: boolean;
};

function DocumentsListWithActions(props: Props) {
  const { bundle, showResponsiveView } = props;
  const { documents } = bundle;
  const { hasPermissionFor } = usePermissions();
  const notTtlExpired = !isTtlExpired(documents);
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const organizationName = bundle.transaction.publicOrganization.name;
  const brandName = brandNameEnabled
    ? bundle.transaction.publicOrganization.organizationBrand.name
    : organizationName;

  return (
    <div
      className={`NotarizationDetails-documents ${
        showResponsiveView ? "NotarizationDetails-responsive" : ""
      }`}
    >
      {!showResponsiveView && (
        <div className="NotarizationDetails-documents-ttlwrapper">
          {brandName && <TtlExpiredAlert orgName={brandName} documents={documents} />}
        </div>
      )}
      <DocumentsListContainer
        bundle={bundle}
        viewable={props.viewable}
        ttlExpired={!notTtlExpired}
        showMergedDocs={props.showMergedDocs}
        onDocumentSelected={props.onDocumentSelected}
        onSupplementaryDocSelected={props.onSupplementaryDocSelected}
        showHeader={props.showHeader}
        showLegalInfoItem={props.showLegalInfoItem}
        showNotarialActs={props.showNotarialActs}
        canViewSplittingResults={hasPermissionFor("canViewSplittingResults")}
        lastOpenedId={props.lastOpenedId}
        showResponsiveView={showResponsiveView}
        downloadable={props.downloadable}
        shareable={props.shareable}
      />
    </div>
  );
}

DocumentsListWithActions.defaultProps = {
  downloadable: true,
  showLegalInfoItem: true,
};

export default DocumentsListWithActions;
